import request from '@/utils/request'
const userApi = {
  add: '/pay/channel/create',
  del: '/pay/channel/delete',
  edit: '/pay/channel/update',
  editStatus: 'pay/channel/update-status',
  detail: '/pay/channel/get',
  detailByMId: '/pay/channel/get-channel', 
  list: '/pay/channel/page',
  listbyIds: '/pay/channel/list',
  orderList: 'pay/order/page',// 订单列表 
  refundOList: 'pay/refund/page', // 退款订单列表
}
// 增
export function add (data) {
  return request({
    url: userApi.add,
    method: 'post',
    data: data
  })
}
// 删
export function del (parameter) {
  return request({
    url: userApi.del,
    method: 'get',
    params: parameter
  })
}
// 改
export function edit (data) {
  return request({
    url: userApi.edit,
    method: 'post',
    data: data
  })
}
// 查 详情
export function detail (parameter) {
  return request({
    url: userApi.detail,
    method: 'get',
    params: parameter
  })
}
// 查 详情 by merchantId
export function detailByMId (parameter) {
  return request({
    url: userApi.detailByMId,
    method: 'get',
    params: parameter
  })
}

// 查 列表
export function list (parameter) {
  return request({
    url: userApi.list,
    method: 'get',
    params: parameter
  })
}
// 查 列表 by Ids
export function listbyIds (parameter) {
  return request({
    url: userApi.listbyIds,
    method: 'get',
    params: parameter
  })
}
// 改 状态 开启和关闭  微信
export function editStatus (data) {
  return request({
    url: userApi.editStatus,
    method: 'post',
    data: data
  })
}
// 订单列表  
export function orderList (parameter) {
  return request({
    url: userApi.orderList,
    method: 'get',
    params: parameter
  })
}
// 退款订单 分页  
export function refundOList (parameter) {
  return request({
    url: userApi.refundOList,
    method: 'get',
    params: parameter
  })
}